import spacing from "./spacing";

//Dimensions for device media queries and layout padding
const dimensions = {
	//pixels
	maxwidthDesktop: 1100,
	maxwidthTablet: 768,
	maxwidthMobile: 600,
};

export const breakpoints = {
	xs: 479,
	phone: dimensions.maxwidthMobile,
	tablet: dimensions.maxwidthTablet,
	tabletLSC: 900,
	desktop: dimensions.maxwidthDesktop,
	bigDesktop: 1800,
};

export const components = {
	header: {
		top: spacing.xLarge,
		height: 80,
	},
	caseStudyBackButton: {
		height: 100,
	},
	container: {
		gap: spacing.xLarge,
		mobileGap: spacing.large,
		maxWidth: 1500,
	}
}

export const respondTo = {
	xsOnly: `@media only screen and (max-width: ${breakpoints.xs}px)`,
	xsUp: `@media only screen and (mix-width: ${breakpoints.xs + 1}px)`,
	smOnly: `@media only screen and (max-width: ${breakpoints.phone}px)`,
	smUp: `@media only screen and (min-width: ${breakpoints.tablet}px)`,
	mdUp: `@media only screen and (min-width: ${breakpoints.tabletLSC}px)`,
	mdDown: `@media only screen and (max-width: ${breakpoints.tabletLSC}px)`,
	lgDown: `@media only screen and (max-width: ${breakpoints.desktop}px)`,
	lgUp: `@media only screen and (min-width: ${breakpoints.desktop}px)`,
	xlUp: `@media only screen and (min-width: ${breakpoints.bigDesktop}px)`,
};

export default dimensions;
