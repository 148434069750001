import React, { useState } from 'react';
import { Global, css, keyframes } from '@emotion/react';

const two = keyframes`
  0% {
    top: 0;
    left: 12px;
    width: 8px;
    height: 8px;
    opacity:0;
  }
  60% {
    opacity: 0;
  }
  100% {
    top: -14px;
    left: -12px;
    width: 100px;
    height: 100px;
    background-color: transparent;
    opacity: 0;
  }
`;

const styles = css`
    .custom:checked ~ .dots .three {
        top: -16px;
        left: 12px;
        height: 40px;
        transform-origin: 50% 50%;
        transform: rotateZ(-45deg);
        border-radius: 2px;
        transition: left 140ms linear, top 140ms linear 140ms, transform 140ms linear 140ms, height 140ms linear 140ms;
    }
    .container {
        position: relative;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .custom {
            position: absolute;
            opacity: 0;
            margin: 0;
            width: 70%;
            height: 70%;
            z-index: 1;
            cursor: pointer;
        }
        
        .dots {
            position: relative;
            top: -8px;
            display: flex;
            width: 36px;
            justify-content: space-between;
        
            .dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #fff;
            }
        
            .one {
                position: absolute;
                left: 0;
                top: 0px;
                transition: left 250ms linear 250ms, top 250ms linear, transform 250ms linear, height 250ms linear, border-radius 250ms linear;
            }
        
            .two {
                position: absolute;
                left: 12px;
                top: 0;
                width: 8px;
                height: 8px;
                box-sizing: border-box;
                background-color: #fff;
                transform-origin: center;
                border: 1px solid #fff;
                opacity: 1;
            }
            
            .three {
                position: absolute;
                left: 24px;
                top: 0;
                transition: left 250ms linear 250ms, top 250ms linear, transform 250ms linear, height 250ms linear, border-radius 250ms linear;
            }
        }
    }
    .custom:checked ~ .dots .one {
        top: -16px;
        left: 12px;
        height: 40px;
        transform-origin: 50% 50%;
        transform: rotateZ(45deg);
        border-radius: 2px;
        transition: left 140ms linear, top 140ms linear 140ms, transform 140ms linear 140ms, height 140ms linear 140ms, opacity 140ms;
    }

    .custom:checked ~ .dots .two {
        animation: ${two} 500ms linear forwards 180ms;
    }
`

export default function MenuIcon({ onClick, checked }) {
	return (
		<>
			<Global styles={styles} />
			<div className="container">
				<input type="checkbox" checked={checked} className="custom" onChange={onClick} />
				<div className="dots">
					<span className="dot one"></span>
					<span className="dot two"></span>
					<span className="dot three"></span>
				</div>
			</div>
		</>
	)
}