// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asahi-js": () => import("./../../../src/pages/asahi.js" /* webpackChunkName: "component---src-pages-asahi-js" */),
  "component---src-pages-coopers-js": () => import("./../../../src/pages/coopers.js" /* webpackChunkName: "component---src-pages-coopers-js" */),
  "component---src-pages-garnier-js": () => import("./../../../src/pages/garnier.js" /* webpackChunkName: "component---src-pages-garnier-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kellogs-js": () => import("./../../../src/pages/kellogs.js" /* webpackChunkName: "component---src-pages-kellogs-js" */),
  "component---src-pages-loreal-men-expert-js": () => import("./../../../src/pages/loreal-men-expert.js" /* webpackChunkName: "component---src-pages-loreal-men-expert-js" */),
  "component---src-pages-loreal-paris-js": () => import("./../../../src/pages/loreal-paris.js" /* webpackChunkName: "component---src-pages-loreal-paris-js" */),
  "component---src-pages-somersby-js": () => import("./../../../src/pages/somersby.js" /* webpackChunkName: "component---src-pages-somersby-js" */),
  "component---src-pages-subway-js": () => import("./../../../src/pages/subway.js" /* webpackChunkName: "component---src-pages-subway-js" */)
}

