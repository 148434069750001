import React from 'react';
import styled from '@emotion/styled';
import spacing, { px } from '../spacing';

const Root = styled.div`
	display: ${(props) => (props.theme.inline ? 'inline-flex' : 'flex')};
	flex-direction: ${props => props.theme.direction};
	width: ${(props) => (props.theme.inline ? 'auto' : '100%')};
	align-items: ${(props) => props.theme.align};
	justify-content: ${(props) => props.theme.justify};
	color: inherit;
	height: ${(props) => props.theme.fullHeight ? '100%' : 'initial'};
	flex-grow: ${props => props.theme.fullHeight | props.theme.fullWidth ? '1' : 'initial'};
	width: ${(props) => props.theme.fullWidth ? '100%' : 'initial'};
	& > * + * {
		margin-${props => props.theme.direction === 'row' ?  'left' : 'top'}: 
			${(props) => props.theme.childGap ? `${px(spacing[props.theme.childGap])}` : 0};
	}
	flex-wrap: ${props => props.theme.wrap};
`;
export default function Flex({
	children,
	align = 'center',
	justify = 'flex-start',
	childGap,
	inline,
	direction,
	fullHeight,
	fullWidth,
	wrap = 'no-wrap',
	...rest
}) {
	return (
		<Root {...rest} theme={{ inline, wrap, align, justify, childGap, direction, fullHeight, fullWidth }}>
			{children}
		</Root>
	);
}
