const palette = {
	midnight: {
		m1: '#002E3F',
		m2: '#295769',
		m3: '#437081',
		m4: '#678C9B',
		m5: '#90ABB5',
		m6: '#C1D1D6',
		m7: '#DFE8EC',
		m8: '#F2F7F9',
	},
	sunrise: {
		s1: '#F89721',
		s2: '#FFA700',
		s3: '#FFBA00',
		s4: '#FFC400',
		s5: '#FFCE00',
		s6: '#FFDE2C',
		s7: '#FCE66C',
		s8: '#FFF09A',
	},
	blue: {
		b1: '#00AAFF',
		b2: '#27B7FF',
		b3: '#4BC3FF',
		b4: '#62CBFF',
		b5: '#78D1FD',
		b6: '#91DBFF',
		b7: '#A7E1FD',
		b8: '#C6ECFF',
	},
	red: {
		r1: '#FF4E00',
		r2: '#FF641F',
		r3: '#FF7A3E',
		r4: '#FF8A55',
		r5: '#FA9668',
		r6: '#FCAA84',
		r7: '#FFBB9B',
		r8: '#FCC8B0',
	},
	purple: {
		p1: '#8900FF',
		p2: '#9112FF',
		p3: '#9B2AFD',
		p4: '#A843FF',
		p5: '#B258FF',
		p6: '#BB6DFD',
		p7: '#C27FFA',
		p8: '#D5A2FF',
	},
	green: {
		g1: '#3EE57E',
		g2: '#3DEF81',
		g3: '#5DF597',
		g4: '#74FCA8',
		g5: '#81FFB1',
		g6: '#98FFBF',
		g7: '#B2FFCF',
		g8: '#D8FFE7',
	},
	turquoise: {
		t1: '#0097A3',
		t2: '#0CA3AF',
		t3: '#16AEBA',
		t4: '#24BAC6',
		t5: '#31C4D0',
		t6: '#41CFDB',
		t7: '#5CDCE6',
		t8: '#81F0F8',
	}
}

export const background = {
	light: '#F7ECE2',
	dark: palette.midnight.m1,
}

export default palette;