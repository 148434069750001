export default {
	xSmall: 4,
	small: 8,
	smallPlus: 12,
	medium: 16,
	mediumPlus: 20,
	large: 24,
	xLarge: 36,
	xxLarge: 40,
	xxxLarge: 60,
};

export const px = val => (typeof val === 'string' ? val : `${val}px`);
