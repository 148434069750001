import * as React from 'react'
import styled from '@emotion/styled'
import './global.css'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Logo from '../images/logo_light.svg'
import palette from '../common/palette'
import Flex from '../common/flex'
import { Global, css } from '@emotion/react'
import { respondTo } from '../common/dimensions'
import MenuIcon from '../common/menu-icon'
import { Link, navigate } from 'gatsby'

const MenuOverlay = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: ${palette.midnight.m1};
	z-index: 999;
	overflow-y: scroll;
	padding: 36px;
	${respondTo.mdUp} {
		ul {
			width: 285px;
			margin: 10% auto auto;
		}
	}
	${respondTo.xsOnly} {
		padding: 24px;
	}
`

const Menu = styled.ul`
	margin-left: 0;
	margin-top: 10%;
	li {
		list-style: none;
	}
	li + li {
		margin-top: 24px;
	}
	a {
		text-decoration: none;
		margin: 0 0 1.45rem 0;
		padding: 0;
		color: inherit;
		font-weight: bold;
		text-rendering: optimizeLegibility;
		font-size: 50px;
		line-height: 1.1;
		font-family: 'minion-pro', Calibri, 'Trebuchet MS', sans-serif;
		color: ${palette.sunrise.s1};

		&:hover,
		&:focus {
			outline: none;
			color: #ffffff;
		}
	}
	p {
		font-size: 16px;
		color: ${palette.midnight.m2};
	}
`

const MenuHeader = styled(Flex)`
	height: 80px;
	a {
		padding: 0;
	}
`

const StyledNav = styled.span`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: transparent;
	height: 80px;
	margin: 36px 36px 0;
	position: fixed;
	top: 0;
	left: 200px;
	right: 0;
	z-index: 1000;
	${respondTo.xsOnly} {
		margin: 24px 24px 0;
	}
`

const menuItems = [
	{
		label: 'About us.',
		id: '#simple',
	},
	{
		label: 'Brands.',
		id: '#clients',
	},
	{
		label: 'Services.',
		id: '#services',
	},
	{
		label: 'Work.',
		id: '#work',
	},
	{
		label: 'Contact.',
		id: '#contact',
	},
]

const hiddenScrollbarStyles = css`
	body::-webkit-scrollbar {
		display: none;
	}
	body {
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
`

const Layout = ({ children }) => {
	const [menuOpen, setMenuOpen] = React.useState(false)

	const handleMenuItemSelection = (scrollLocation, event) => {
		event.preventDefault()

		if (window.location.pathname !== '/') {
			navigate(`/${scrollLocation}`)
		} else {
			scrollTo(scrollLocation)
		}
		setTimeout(() => {
			setMenuOpen(false)
		}, 100)
	}

	return (
		<>
			<Global styles={menuOpen ? hiddenScrollbarStyles : ``} />
			{children}
			<StyledNav>
				<MenuIcon
					checked={menuOpen}
					onClick={() => {
						setMenuOpen(!menuOpen)
					}}
				/>
			</StyledNav>
			{menuOpen && (
				<MenuOverlay>
					<MenuHeader align="center" justify="space-between">
						<a href="/#main" onClick={() => handleMenuItemSelection('#main')}>
							<Logo />
						</a>
					</MenuHeader>
					<nav>
						<Menu>
							{menuItems.map((item, index) => {
								const itemCount = '0' + (index + 1)
								return (
									<li key={'menuItem' + item.id}>
										<p>{itemCount}</p>
										<Link
											to={`/${item.id}`}
											onClick={handleMenuItemSelection.bind(null, item.id)}
										>
											{item.label}
										</Link>
									</li>
								)
							})}
						</Menu>
					</nav>
				</MenuOverlay>
			)}
		</>
	)
}

export default Layout
